<template>
  <ItemList
    :items="shippings"
    :is-loading="isLoadingList"
    no-data-text="Nenhuma Frete encontrado"
    label="Frete"
    @addItem="addShipping"
    @removeItem="removeShipping"
  />
</template>

  <script>
  export default {
    components: {
      ItemList: () => import('./ItemList.vue'),
    },
    data: () => ({
      shippings: [],
      isLoadingList: false,
    }),
    mounted() {
      if (this.shippings.length === 0) {
        this.getSegments()
      }
    },
    methods: {
      getSegments() {
        this.isLoadingList = true
        this.$api
          .get('budget/shipping')
          .then((res) => {
            this.shippings = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoadingList = false
          })
      },
      addShipping(value) {
        this.$api
          .post('budget/shipping/store', { name: value })
          .then((res) => {
            this.shippings.push(res.data.data)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      removeShipping(item) {
        this.$api
          .post('budget/shipping/delete', item)
          .then(() => {
            let idx = this.shippings.indexOf(item)
            this.shippings.splice(idx, 1)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
  </script>